var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Hidrocarburos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Transporte Fluvial")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Reportes")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card-body p-5"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row clearfix"},[(
                    _vm.$store.getters.can(
                      'hidrocarburos.transporte.fluvial.reportes.exportGeneral'
                    )
                  )?_c('div',{staticClass:"col-md-3 column"},[_vm._m(2)]):_vm._e()])])])])])])]),_c('ReporteGeneralExport',{ref:"ReporteGeneralExport"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Reportes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header pt-2 pb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-11"}),_c('div',{staticClass:"input-group"},[_c('h1',{staticClass:"m-0 text-dark text-center"},[_vm._v(" Tablero de Reportes ")]),_c('div',{staticClass:"input-group-append"})]),_c('div',{staticClass:"btn-group float"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-widget widget-user"},[_c('button',{staticClass:"btn text-white btn-default pt-4 pb-4",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-reporte-general-export"}},[_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-file-alt fa-3x",staticStyle:{"color":"#31c177"}})])]),_c('div',{staticClass:"m-0 text-dark text-center"},[_c('h4',[_vm._v("Reporte General")])])])])
}]

export { render, staticRenderFns }