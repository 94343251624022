<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Reportes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Transporte Fluvial</li>
                  <li class="breadcrumb-item active">Reportes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card-body p-5">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="input-group">
                    <h1 class="m-0 text-dark text-center">
                      Tablero de Reportes
                    </h1>
                    <div class="input-group-append"></div>
                  </div>
                  <div class="btn-group float"></div>
                </div>
              </div>
              <div class="card-body">
                <div class="row clearfix">
                  <div
                    class="col-md-3 column"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.transporte.fluvial.reportes.exportGeneral'
                      )
                    "
                  >
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-general-export"
                      >
                        <p align="center">
                          <span class="icon"
                            ><i
                              class="fas fa-file-alt fa-3x"
                              style="color: #31c177"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Reporte General</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <ReporteGeneralExport ref="ReporteGeneralExport" />
  </div>
</template>

<script>

import axios from "axios";
import ReporteGeneralExport from './ReporteGeneralExport.vue';

export default {
  name: "ReporteIndex",
  data() {
    return {
      cargando: false,
      sitio_origen: {},
      sitio_destino: {},
      listasForms: {
        productos: [],
        sitiosOrigenes: [],
        sitiosDestinos: [],
        empresas: [],
        listaConsultas: [],
      },
    };
  },
  components: {
    ReporteGeneralExport
  },
  methods: {
    async getProducto() {
      await axios.get("api/admin/productos/lista").then((response) => {
        this.$refs.ReporteGeneralExport.listasForms.productos =
          response.data;
      });
    },
    async getSitios() {
      await axios.get("api/admin/sitios/lista").then((response) => {
        this.$refs.ReporteGeneralExport.listasForms.sitiosDestinos =
          response.data;
      });
    },
    async getBarcazas() {
      axios
        .get("/api/admin/barcazas/lista")
        .then((response) => {
          this.$refs.ReporteGeneralExport.listasForms.barcazas = response.data;
        });
    }
  },

  async mounted() {
    this.cargando = true;
    await this.getSitios();
    await this.getProducto();
    await this.getBarcazas();
    this.cargando = false;
  }
};
</script>
